.scrollable {
  position: absolute;
  width: 40%;
  top: 0;
  right: 0;
  will-change: transform;
}
.img_content {
  position: relative;
  width: 100%;
}

.home_gallery {
  .img_section {
    position: relative;
    height: 50vh;
    margin: 2rem 0;

    figure {
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        display: block;
      }

      span {
        position: absolute;
        bottom: 0;
        left: -5rem;
        font-size: 1.4rem;
        font-family: $Paragraphe;
      }
    }
    .img_caption {
      font-family: $Paragraphe;
      font-size: 1.6rem;
      font-weight: lighter;
      text-transform: uppercase;
      position: absolute;
      bottom: 0;
      line-height: auto;
      text-align: right;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      will-change: transform;
      > * {
        transform: translateY(100%);
        margin-right: 1.5rem;
        transition: 0.5s;
        will-change: transform;
      }
      > *.active {
        transform: translateY(0%);
      }
    }
  }
}
