* {
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
}
*:after,
*:before {
  box-sizing: border-box;
  user-select: none;
}
:root {
  --main-bg-color: $DesertStorm;
}
a {
  color: inherit;
  outline: none;
  text-decoration: none;
}
html,
body {
  font-size: calc(100vw / 1920 * 10);
  height: 100%;
  background: var(--main-bg-color);
  height: 100%;
  position: relative;
  overscroll-behavior: none;
}
::-webkit-scrollbar {
  display: none;
}
