main {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  .home_text {
    position: fixed;
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      font-family: $Paragraphe;
      font-size: 1.4rem;
      color: $CodGray;
      margin-top: 10rem;
      width: 26rem;
      letter-spacing: 0.1rem;
      line-height: 1.6rem;
    }
  }
}
