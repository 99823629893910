.loader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: $CodGray;
  color: $DesertStorm;
  transition: 1.5s;

  .logo_loader {
    transform: translate(-50%, -50%) rotate(-90deg) scaleY(1);
    transform-origin: center center;
    position: absolute;
    top: 50%;
    left: 50%;
    padding-bottom: 10rem;
    font-family: $Title;
    font-size: 30rem;
    line-height: 1;
    letter-spacing: 1rem;
    opacity: 0;
    transition: 1s;

    &.active {
      opacity: 1;
      transform: translate(-50%, -50%) rotate(-90deg) scaleY(2);
    }
  }
}
