@font-face {
  font-family: 'CF Paris';
  src: url('../fonts/CFParis-Regular.woff2') format(woff2);
}
@font-face {
  font-family: 'Raleway';
  font-weight: normal;
  font-style: normal;
  src: url('../fonts/Raleway-Regular.woff2') format(woff2);
}
