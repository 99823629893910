header {
  position: fixed;
  top: 2rem;
  left: 2rem;
  width: 37rem;
  h1 {
    font-family: $Title;
    font-size: 10rem;
    color: $Title;
    letter-spacing: 1rem;
    transform: scaleY(3);
  }

  h2 {
    font-family: $Paragraphe;
    font-size: 2rem;
    color: $CodGray;
    margin-top: 10rem;
    float: right;
  }
}
footer {
  position: fixed;
  bottom: 2rem;
  left: 2rem;
  width: 50%;
  display: flex;
  justify-content: space-around;

  a {
    font-family: $Paragraphe;
    font-size: 1.6rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
  }
}
